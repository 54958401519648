/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

@import '~swiper/swiper';



/* IMPORTACION DE FUENTES DEL PROYECTO */
@font-face {
  font-family: 'Montserrat-Bold';    //This is what we are going to call
  src: url('./assets/fonts/Montserrat-Bold.ttf');


}

@font-face {
    font-family: 'Montserrat-Regular';    //This is what we are going to call
    src: url('./assets/fonts/Montserrat-Regular.ttf');
  }

  @font-face {
    font-family: 'Montserrat-Semi-Bold';    //This is what we are going to call
    src: url('./assets/fonts/Montserrat-SemiBold.ttf');
  }

  ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
  }

  ion-modal.fullscreenreview {
    --width: 75%;
    --height: 200px;
    --border-radius: 0;
  }


  ion-modal.stack-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  }

  .loader-class {
    background-color: transparent !important;
  }
